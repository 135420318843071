<template>
  <v-dialog
    :model-value="modelValue"
    v-bind="safeAttrs"
    class="posiiton-relative"
    @update:model-value="$emit('update:modelValue')"
  >
    <v-card
      flat
      :rounded="0"
      class="pb-16 position-static"
      :color="safeAttrs?.color"
    >
      <v-card-item class="mb-4">
        <slot name="default">
          <!--injected component goes here-->
        </slot>
      </v-card-item>

      <v-toolbar
        absolute
        class="position-bottom"
        :color="safeAttrs?.color"
        elevation="4"
      >
        <slot name="actions">
          <!--injected actions goes here-->
        </slot>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { defaults } from 'lodash-es';
import { useDisplay } from 'vuetify';

export default defineComponent({
  name: 'MtfDialog',
  inheritAttrs: true,
  customOptions: {},
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue'],
  computed: {
    safeAttrs() {
      const { width, mobile } = useDisplay();

      return defaults(
        { ...this.$attrs },
        {
          // transition: 'v-dialog-bottom-transition',
          width: mobile.value ? width.value : 720,
          persistent: true
        }
      );
    }
  }
});
</script>

<style lang="scss" scoped>
.position-top {
  top: 0 !important;
}

.position-bottom {
  bottom: 0 !important;
}
</style>
